import React from 'react';
import styled from '@emotion/styled';

const Privacy = () => {
    return (
        <Wrapper>
            <h1>Pomodorino</h1>
            <h2>Privacy Policy</h2>

            <p>
                This policy applies to all information collected or submitted on this website and our apps for iPhone
                and any other devices and platforms.
            </p>

            <h3>Information we collect</h3>
            <p>
                Pomodorino app doesn’t collect any data from the user. All data is stored locally on the User’s device.
            </p>

            <h3>Technical basics</h3>
            <p>
                If you enable notifications, we must store a token to send them. We never use notifications for
                marketing.
            </p>

            <h3>Ads and analytics</h3>
            <p>
                Pomodorino app collects aggregate, anonymous statistics, such as the percentage of users who use
                particular features, to improve the app.
            </p>

            <h3>Information usage</h3>
            <p>
                We use the information we collect to operate and improve our website, apps, and customer support. We do
                not share personal information with outside parties except to the extent necessary to accomplish
                Pomodorino functionality. We may disclose your information in response to subpoenas, court orders, or
                other legal requirements; to exercise our legal rights or defend against legal claims; to investigate,
                prevent, or take action regarding illegal activities, suspected fraud or abuse, violations of our
                policies; or to protect our rights and property. In the future, we may sell to, buy, merge with, or
                partner with other businesses. In such transactions, user information may be among the transferred
                assets.
            </p>

            <h3>Security</h3>
            <p>
                We implement a variety of security measures to help keep your information secure. For instance, all
                communication with the app and website requires HTTPS with certificate pinning.
            </p>

            <h3>Accessing, changing, or deleting information</h3>
            <p>You may delete your information just by deleting Pomodorino app from your device.</p>

            <h3>California Online Privacy Protection Act Compliance</h3>
            <p>
                We comply with the California Online Privacy Protection Act. We therefore will not distribute your
                personal information to outside parties without your consent.
            </p>

            <h3>Children’s Online Privacy Protection Act</h3>
            <p>
                Compliance We never collect or maintain information at our website from those we actually know are under
                13, and no part of our website is structured to attract anyone under 13.
            </p>

            <h3>Information for European Union Customers</h3>
            <p>
                By using Pomodorino App and providing your information, you authorize us to collect, use, and store your
                information outside of the European Union. International Transfers of Information Information may be
                processed, stored, and used outside of the country in which you are located. Data privacy laws vary
                across jurisdictions, and different laws may be applicable to your data depending on where it is
                processed, stored, or used.
            </p>

            <h3>Your Consent</h3>
            <p>By using our site or apps, you consent to our privacy policy.</p>
        </Wrapper>
    );
};

export default Privacy;

const Wrapper = styled.div`
    margin: 0 auto;
    padding: 16px;
    @media only screen and (min-width: 768px) {
        max-width: 60%;
    }
`;
